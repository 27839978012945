import React, { useEffect, useState, useRef } from "react";
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    LinearProgress,
    Box,
    Stack,
    Modal,
    Typography,
    Tooltip,
    Radio,
    Paper,
    TextareaAutosize,
    CircularProgress
} from "@mui/material";
import { makeStyles } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useGenerateBusinessBlogMutation, useGeneratedbusinessurlblogsMutation, useGetBlogidQuery } from "../../redux/api/promptApi";
import { useGetUserDetailsMutation } from "redux/api/authApi";
import { IGetBlogId } from '../../redux/api/types';
import { useNavigate, useLocation } from "react-router-dom";
import PayNowButton from '../../pages/payment/payNowButton';
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { usePlanDetailsMutation } from "../../redux/api/productApi";
import { responsiveProperty } from "@mui/material/styles/cssUtils";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { object, string, TypeOf, number } from "zod";
import CloseIcon from '@mui/icons-material/Close';
import ProgressBar from "@ramonak/react-progress-bar";

const GenerateBlog = object({
    userid: string(),
    url: string(),
    product_desc: string(),
    tone: string(),
    sourcemode: string(),
    videoid: string(),
    media_file_path: string()

})

export type CreateBlog = TypeOf<typeof GenerateBlog>

const BorderLinearProgress = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const Dashboard_tm = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const { CreatingfromLanding } = location.state || {};
    const { UrlfromLanding } = location.state || {};

    const [userBusinessUrl, setUserBusinessUrl] = useState<any>();
    const [userBusinessText, setUserBusinessText] = useState('');
    const [uploadVideo, setUploadVideo] = useState('');
    const [userBusinessurl2, setUserBusinessUrl2] = useState('');
    const [podcastoryoutubeurl, setPodcastOrYoutubeUrl] = useState('');
    const [selectedSection, setSelectedSection] = useState('websiteSection');
    const [isLoading, setIsLoading] = useState(false);
    const [openPaidModal, setOpenPaidModal] = useState(false);
    const [openpreviewBlogModal, setOpenPreviewBlogModal] = useState(false);
    const [businessBlogMutation] = useGenerateBusinessBlogMutation();
    const freePlan = parseInt(localStorage.getItem('free_plan') || '');
    const payment = localStorage.getItem('payment');
    // const subscription_level = localStorage.getItem('subscription_level');
    const [toneSelection, setToneSelection] = useState<any>();
    const [httpsVal, setHttpsVal] = useState(localStorage.getItem('uploadedText') ? 'text' : 'https://');
    const [fieldType, setFieldType] = useState<any>('url');
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [planDetails, setPlanDetails] = useState<any>();
    const [subscription_level, setSubscriptionLevel] = useState<any>();
    const [userDets, setUserDets] = useState<any>();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();
    const [isTriggered, setIsTriggered] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [uploadedvideopath, setUploadedvideopath] = useState('');
    const [firstBlogId, setFirstBlogId] = useState<any>();
    const [firstBlogData, setFirstBlogData] = useState<any>();
    const [firstProductId, setFirstProductId] = useState<any>();
    const [blogDetails, setBlogDetails] = useState<IGetBlogId[]>([]);
    const [cta, setCta] = useState("")
    const [ctalabel, setCtaLabel] = useState("")
    const [isPreviewLoading, setPreviewLoading] = useState<boolean>(true);

    const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();
    const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();
    const [loaderProgress, setLoaderProgress] = useState<any>(4);

    useEffect(() => {
        const loggedIn = localStorage.getItem("logged_in");
        if (loggedIn !== "true") {
            navigate("/signin");
        }
    }, [navigate]);

    const user_email = localStorage.getItem('user_email') || '';

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email || '', campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                console.log('plan details=>', response.data.data);
                setSubscriptionLevel(planDetails.subscription_level);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
        console.log(subscription_level);
    }, [subscription_level]);

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: user_email || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
                // console.log('userDetails=>', response.data.data);
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let intervalId: any;
        if (isLoading && loaderProgress < 100) {
            intervalId = setInterval(() => {
                setLoaderProgress((prevProgress: any) => {
                    const newProgress = prevProgress + 2;
                    return newProgress >= 98 ? 98 : newProgress;
                });
            }, 2000);
        }

        return () => clearInterval(intervalId);
    }, [isLoading, loaderProgress]);


    const handleGenerateBlogClickold = async (userid: string, url: string, tone: any, product_desc: any) => {
        if (/* freePlan === 0 &&  */subscription_level === "") {
            setOpenPaidModal(true);
        } else if ((selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code) && !url) {
            toast.error('Please enter your youtube/podcast url!');
        }
        else if (freePlan === 1 || payment === 'Success' || subscription_level === 'level_1' || subscription_level === 'level_2' || subscription_level === 'level_3' || subscription_level === 'level_4' || subscription_level === 'level_5' || subscription_level === 'level_6' ||
            planDetails.subscription_level === 'level_1' || planDetails.subscription_level === 'level_2' || planDetails.subscription_level === 'level_3' || planDetails.subscription_level === 'level_4' || planDetails.subscription_level === 'level_5' || planDetails.subscription_level === 'level_6'
        ) {
            setIsLoading(true);


            try {

                // const youtubeMatch = podcastoryoutubeurl?.match(
                //     /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                // );
                var tmpurl = httpsVal === 'https://' ? httpsVal + (document.getElementById('sourceurl') as HTMLInputElement)?.value : '';

                let youtubeMatch;
                let videoid = "";
                // let tmpurl = "";
                // if (userDets?.appsumo_coupon_code || selectedSection === 'podcastSection') {

                /* if (InputFromLanding !== '') {
                    youtubeMatch = InputFromLanding.match(
                        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                    );
                    videoid = youtubeMatch ? youtubeMatch[1] : '';
                } else { */
                tmpurl = httpsVal === 'https://' ? httpsVal + (document.getElementById('sourceurl') as HTMLInputElement)?.value : '';

                youtubeMatch = tmpurl.match(
                    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                );
                videoid = youtubeMatch ? youtubeMatch[1] : '';
                // }

                // }
                const url = /* tmpurl !== '' ? encodeURIComponent(tmpurl) : */ '';

                const result = await businessBlogMutation({ userid, url, tone, sourcemode: /* selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code ? 'youtube' : */ httpsVal === 'upload' ? 'upload' : 'youtube'/* 'url' */, videoid: videoid ? videoid : '', product_desc, media_file_path: '' });

                if ('data' in result) {
                    const { data } = result;

                    // Increment the generating count
                    const generatingCount = localStorage.getItem('Generating Count');
                    const newGeneratingCount = generatingCount ? parseInt(generatingCount) + 1 : 1;
                    localStorage.setItem('Generating Count', newGeneratingCount.toString());

                    localStorage.setItem("finalData", JSON.stringify(data.data).replace(/\*\*/g, '')
                        .replace(/\#\#\#/g, '')
                        .replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '')
                        .replace(/<h3>/g, '<h5>')
                        .replace(/<\/h3>/g, '</h5>')
                        .replace(/<h2>/g, '<h5>')
                        .replace(/<\/h2>/g, '</h5>'));
                    // localStorage.setItem('Blogs Generated', 'true');

                    if (data.result === 'error') {
                        toast.error(data.message);
                        console.log(data.message);
                    } else if (data.result === 'success') {
                        navigate('/codegenie/generated-blogs', { state: { FromDashboard: "true" } });
                        console.log('Data:', data.result);
                    }
                } else {
                    const errorMessage = (result?.error as any)?.data?.detail as string | undefined;
                    toast.error(errorMessage);
                    // setIsLoading(false);
                    //window.location.reload();
                }
            } catch (error) {
                console.log('Unexpected Error:', error);
            } finally {
                toast.error('Something went wrong!');
                // setIsLoading(false);
                // navigate('/codegenie/generated-blogs');
            }
        }
    }

    const handleGenerateBlogClick = async (
        userid: string,
        url: string,
        tone: any,
        // media_file_path: any
        uploadedtext: any
    ) => {
        if (subscription_level === "") {
            setOpenPaidModal(true);
        } else if ((selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code) && !url) {
            toast.error('Please enter your YouTube/Podcast URL!');
        } else if (
            freePlan === 1 ||
            payment === 'Success' ||
            subscription_level.startsWith('level_') ||
            planDetails.subscription_level.startsWith('level_')
        ) {

            /* let tmpurl = httpsVal === 'https://'
                ? httpsVal + (document.getElementById('sourceurl') as HTMLInputElement)?.value
                : ''; */

            /* let youtubeMatch = tmpurl.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
            ); */
            /*  let youtubeMatch = tmpurl.match(
                 /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?/
             ); */

            if (url || uploadedtext) {

                setIsLoading(true);
                // setOpenPreviewBlogModal(true);
                // setPreviewLoading(true);
                // let intervalId: ReturnType<typeof setInterval>;
                let generationSuccess = false;
                // intervalId = setInterval(async () => {
                //     try {
                //         const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';

                //         const blogResponse = await axios.get(`${apiUrl}/api/prompts/listBlogsByUserID?userid=${userid}`);
                //         if (blogResponse.data.status === 'success' && blogResponse.data.data.length > 0) {
                //             setFirstBlogData(blogResponse.data);
                //             const firstBlogId = blogResponse.data.data[0]._id;
                //             const firstProductId = blogResponse.data.data[0].product_id;
                //             setFirstBlogId(firstBlogId);
                //             setFirstProductId(firstProductId);
                //             console.log('First Blog data', blogResponse.data);

                //             if (blogResponse.data.data[0].blog_det) {
                //                 // Clear interval if `blog_det` exists
                //                 clearInterval(intervalId);
                //                 setPreviewLoading(false);
                //             }
                //         }
                //     } catch (error) {
                //         console.error('Error fetching blogs:', error);
                //     }
                // }, 10000);


                try {
                    // let videoid = youtubeMatch ? youtubeMatch[1] : '';

                    const formData = new FormData();
                    formData.append('userid', userid);
                    formData.append('url', url);
                    formData.append('tone', '');
                    formData.append('sourcemode', httpsVal === 'text' ? 'text' : 'url');
                    formData.append('videoid', '');
                    formData.append('product_desc', uploadedtext);
                    formData.append('media_file_path', '');

                    const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';



                    const response = await axios.post(
                        `${apiUrl}/api/prompts/get_page`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    console.log(response);
                    if (response.data.result === 'success') {
                        setLoaderProgress(100);
                        generationSuccess = true;
                        toast.success('Blog generated successfully!');
                        localStorage.setItem('finalData', JSON.stringify(response.data.data)
                            .replace(/\*\*/g, '')
                            .replace(/\#\#\#/g, '')
                            .replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '')
                            .replace(/<h3>/g, '<h5>')
                            .replace(/<\/h3>/g, '</h5>')
                            .replace(/<h2>/g, '<h5>')
                            .replace(/<\/h2>/g, '</h5>'));

                        setTimeout(() => {
                            navigate('/codegenie/generated-blogs', { state: { FromDashboard: "true" } });
                        }, 2000);
                    } else {
                        toast.error(response.data.message || 'Something went wrong!');
                    }
                } catch (error) {
                    console.error('Unexpected Error:', error);
                    // toast.error('An unexpected error occurred. Please try again.');
                } finally {
                    /*  if (!generationSuccess) {
                         clearInterval(intervalId);
                     } */
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2000);
                }
            } else if (!url || !uploadedtext) {
                toast.error('Please enter valid business url/text!');
            }
        }
    };

    /*  const { data: getblogdetails } = useGetBlogidQuery({ blog_id: firstBlogId, campId: firstProductId });
 
     useEffect(() => {
 
         if (getblogdetails && getblogdetails.data) {
 
 
             if (getblogdetails.data[0]['statichtml'] && getblogdetails.data[0]['mode'] == 'Live' && (firstProductId == "" || firstProductId == "undefined")) { //if static html generated redirec to that page
 
                 window.location.href = getblogdetails.data[0]['canonical_url'];
                 return;
             }
             console.log('getblogdetails.data----');
             if (getblogdetails.data[0]['cta_label'])
                 setCtaLabel(getblogdetails.data[0]['cta_label'])
             if (getblogdetails.data[0]['cta_url'])
                 setCta(getblogdetails.data[0]['cta_url'])
             setBlogDetails([...getblogdetails.data])
             setPreviewLoading(false);
         }
     }, [getblogdetails]) */


    /* const handleUrlCheck = () => {
        const urlFromLanding = localStorage.getItem('UrlfromLanding');
        const userEmail = user_email || ''; // Ensure user_email is available

        // Check if there's a URL from landing and the function hasn't been triggered yet
        if (urlFromLanding && !isTriggered) {
            isTriggered = true; // Set to true to prevent further triggers
            handleGenerateBlogClick(userEmail, '', '', '');
        }
    };

    // Call handleUrlCheck when the component mounts or whenever relevant state changes
    useEffect(() => {
        handleUrlCheck();
    }, []); */
    const handleUrlCheck = () => {
        const urlFromLanding = localStorage.getItem('UrlfromLanding');
        const TextFromLanding = localStorage.getItem('uploadedText');
        const userEmail = user_email || '';

        if (urlFromLanding && !isTriggered) {
            console.log("inside handleUrlCheck...");
            setIsTriggered(true);
            handleGenerateBlogClick(userEmail, urlFromLanding, '', '');
        } else if (TextFromLanding && !isTriggered) {
            console.log('inside videoid');
            setIsTriggered(true);
            handleGenerateBlogClick(userEmail, '', '', TextFromLanding);
        }
    };
    const didCall = useRef(false);

    useEffect(() => {
        // handleUrlCheck();

        if (!didCall.current) {
            handleUrlCheck();
            didCall.current = true;
        }
    }, []);


    const handledropdownChange = (event: any) => {
        setSelectedSection(event.target.value);
    }

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputUrl = event.target.value.trim();
        if (inputUrl.startsWith('https://')) {
            inputUrl = inputUrl.replace('https://', '');
        }

        setUserBusinessUrl(inputUrl);

        const urlFromLanding = localStorage.getItem('UrlfromLanding');
        if (urlFromLanding) {
            handleGenerateBlogClick(user_email || '', '', '', '');
        }
    };

    const handleTextChange = (event: any) => {
        let inputText = event.target.value;
        setUserBusinessText(inputText);
    };

    const urlFromLocal = () => {
        const storedUrl = localStorage.getItem('UrlfromLanding') || '';
        if (storedUrl && storedUrl.startsWith('https://')) {
            return storedUrl.replace('https://', '');
        }
        return storedUrl;
    };

    // console.log(InputFromLanding);

    const handlevideoChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUploadVideo(file.name);
            console.log('Uploaded video file:', file);
            const formData = new FormData();
            formData.append('media_file', file);

            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            setIsUploadLoading(true);
            try {
                const response = await axios.post(
                    `${apiUrl}/api/prompts/upload_media_file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response.data.data);
                if (response.data.result === 'success' && response.data.data) {
                    setUploadedvideopath(response.data.data);
                    toast.success('File Uploaded Successfully!');
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                toast.error('Error uploading video');
            } finally {
                setIsUploadLoading(false);
            }

        }
    };

    return (
        <>
            {/* <div className="row" style={{ width: '100%' }}>
                <div className="col-md-12">
                    <Tooltip title="Click here for help video">
                        <img src={youtubeIco1} style={{ margin: '2%' }} onClick={() => window.open("https://youtube.com/embed/ocbezJpEohM?autoplay=1&mute=0&rel=0", '_blank')} />
                    </Tooltip>
                </div>
            </div> */}

            {/* website url section*/}
            <div style={{ margin: '12%', marginTop: '6%', marginBottom: '1%' }}>
                <h3 style={{ fontWeight: 'bolder' }}>
                    {/* {selectedSection === 'websiteSection' && !userDets?.appsumo_coupon_code ? */}
                    <Typography>Generate Blogs with Code Genie AI</Typography>
                    {/* :
                            <Typography>Traffic Monster will generate blogs from your podcast or video.</Typography> */}
                    {/* } */}
                </h3>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        {/* <TextField
                            fullWidth
                            variant="outlined"
                            value={httpsVal}
                            InputProps={{
                                readOnly: true,
                                style: { color: '#4AA2E7', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff' }
                            }}
                        /> */}
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 1,
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                width: '100%',
                            }}
                        >
                            <Select
                                value={httpsVal}
                                onChange={(event) => setHttpsVal(event.target.value)}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    '.MuiInputBase-root': {
                                        height: '100%'
                                    },
                                    backgroundColor: 'white',
                                    color: '#4BA5EB',
                                    '& .MuiSelect-icon': {
                                        color: '#4BA5EB',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    pointerEvents: isLoading ? 'none' : 'auto',
                                }}
                            >
                                <MenuItem value="https://">https://</MenuItem>
                                <MenuItem value="text">text</MenuItem>
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {httpsVal === 'text' ? (
                            <TextareaAutosize style={{ width: '100%', height: '200px', borderColor: '#CBCBCB', borderRadius: '5px', overflow: 'auto' }}
                                value={localStorage.getItem('uploadedText') || userBusinessText}
                                onChange={handleTextChange}
                                readOnly={isLoading}
                                placeholder="Enter your business text"></TextareaAutosize>

                        )
                            :
                            <TextField
                                fullWidth
                                label={"Enter your business website URL"}
                                variant="outlined"
                                value={/* (localStorage.getItem('UrlfromLanding') !== 'undefined' || localStorage.getItem('UrlfromLanding') !== '') ? localStorage.getItem('UrlfromLanding') */ urlFromLocal() || userBusinessUrl}
                                onChange={handleUrlChange}
                                id="sourceurl"
                                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff', color: '#4AA2E7' }}
                                InputLabelProps={{ style: { color: '#4AA2E7' } }}
                                InputProps={{
                                    readOnly: isLoading,
                                }}
                            />}
                        {/* {isMobile ? <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                                defaultValue="url"
                                variant="outlined"
                                onChange={(event) => setFieldType(event.target.value)}
                                style={{ paddingTop: 2, paddingBottom: 2, height: 45, color: '#000', backgroundColor: '#CBCBCB', marginTop: '4%' }}
                            >
                                <MenuItem value="url">URL</MenuItem>
                            </Select>
                        </FormControl> : ''} */}
                    </Grid>

                    <Grid item xs={6} sm={2}>
                        <Button style={{ height: httpsVal === 'text' ? '56px' : '-webkit-fill-available', color: '#fff', filter: isLoading ? 'blur(2px)' : 'none', pointerEvents: isLoading ? 'none' : 'auto' }} variant="contained" fullWidth /* disabled={selectedSection !== 'websiteSection'} */ onClick={() => {
                            // Get the current URL from TextField
                            const currentUrl = localStorage.getItem('UrlfromLanding') && localStorage.getItem('UrlfromLanding') !== 'undefined'
                                ? localStorage.getItem('UrlfromLanding')
                                : userBusinessUrl;
                            const currentText = localStorage.getItem('uploadedText') && localStorage.getItem('uploadedText') !== 'undefined'
                                ? localStorage.getItem('uploadedText')
                                : userBusinessText;
                            if ((httpsVal !== 'text' && currentUrl) || (httpsVal === 'text' && currentText && user_email)) {
                                const decodedBusinessUrl = httpsVal === 'https://' ?/*  encodeURIComponent( */httpsVal + currentUrl/* ) */ : '';
                                const uploadedtext = currentText/* encodeURIComponent(uploadVideo) */;
                                const email = user_email || '';

                                handleGenerateBlogClick(email, decodedBusinessUrl, toneSelection, uploadedtext);
                            } else {
                                if (httpsVal === 'text' && !currentText) {
                                    toast.error("Please upload your text.");
                                } else if (httpsVal !== 'text' && !currentUrl) {
                                    toast.error("Please enter your Business URL.");
                                } else {
                                    toast.error("Please enter your Business URL or text.");
                                }

                                // console.log("Validation failed: youtubeUrl or video is missing.");
                            }
                        }}>
                            {'Generate Blog'}
                        </Button>
                    </Grid>
                </Grid>

                {/* {selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code ?
                    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                        <Grid item xs={12} sm={12} >

                            <TextField
                                fullWidth
                                label="Enter your podcast/youtube URL"
                                variant="outlined"
                                value={podcastoryoutubeurl}
                                onChange={(e) => setPodcastOrYoutubeUrl(e.target.value)}
                                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff', color: '#4AA2E7' }}
                                InputLabelProps={{ style: { color: '#4AA2E7' } }}

                            />
                        </Grid>
                    </Grid>
                    : ''} */}

                <Grid container>
                    <Grid item xs={12} sm={12}>
                        {/* <Typography>{fieldType === 'url' ? 'NOTE: Please copy/paste your website url from your browser.  This url should normally be the main landing page of your website that describes your products or services.  We will use the data from this page to create personalized blogs and content to represent your brand.<br/>Note that you will NOT be able to change this website url later' : ((fieldType === 'upload' || fieldType === 'addtext') ? 'NOTE: Tell us details about your company, products, services that you want to create blogs about.' : '')}</Typography> */}
                        {/* {selectedSection === 'websiteSection' ? */}
                        {/* <Typography>NOTE: Please copy/paste your website url from your browser.  This url should normally be the main landing page of your website that describes your products or services.  We will use the data from this page to create personalized blogs and content to represent your brand.<br />Note that you will NOT be able to change this website url later</Typography> */}
                        {/* : */}
                        <Typography>{httpsVal === 'https://' ? 'NOTE: Please copy/paste your website url from your browser.  This url should normally be the main landing page of your website that describes your products or services.  We will use the data from this page to create personalized blogs and content to represent your brand. Note that you will NOT be able to change this website url later' : 'NOTE: Tell us details about your company, products, services that you want to create blogs about.'}</Typography>
                        {/* } */}
                    </Grid>
                </Grid>
                {/* </Grid> */}

                {/* </Grid> */}
            </div>
            {/* section end */}

            {/* Loader section */}
            {isLoading && (
                <div style={{
                    width: '100%',
                    height: '100%',
                    marginLeft: '12%',
                }}>
                    <div style={{
                        width: '75%',
                        borderRadius: '5px',
                        // backgroundColor: '#fff',
                        textAlign: 'center',
                        marginTop: '6%',
                    }}>
                        <h4 style={{ textAlign: 'center', color: '#fff', fontWeight: '300px' }}>Creating Blogs with Ocesha Ai Agents. This could take a couple of minutes …</h4>
                        <br />
                        {/* <LinearProgress color="primary" /> */}
                        {/* <span style={{ color: '#fff' }}>{loaderProgress}%</span> */}
                        {/* <br /> */}
                        <ProgressBar
                            completed={loaderProgress}
                            bgColor={loaderProgress >= 90 ? "#4CAF50" : "#4BA5EB"}
                            height="20px"
                            labelColor="#ffffff"
                            labelAlignment="center"
                        />
                    </div>
                </div>
            )}
            {/*  {isLoading && (
                <Grid container spacing={2} sx={{ marginTop: '2%' }}>
                    {!isMobile ? <Grid item xs={12} sm={2}>
                        &nbsp;
                    </Grid> : ''}
                    <Grid item xs={12} sm={8} sx={{ margin: isMobile ? '4%' : '' }}>
                        <Box sx={{ background: '#fff', padding: '10%', borderRadius: '10px' }}>
                            <Typography sx={{ fontWeight: 'Bolder', color: '#000', fontSize: 'large' }}>Creating Blogs with Code Genie Ai Agents. This could take a couple of minutes …</Typography>
                            <BorderLinearProgress variant="determinate" value={isLoading ? 50 : 100} sx={{ marginTop: '4%' }} />
                        </Box>
                    </Grid>
                    {!isMobile ? <Grid item xs={12} sm={2}>
                        &nbsp;
                    </Grid> : ''}
                </Grid>
            )} */}
            {/* section end */}

            {/* Preview 0th index blog popup */}
            {/* <Modal
                open={openpreviewBlogModal}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setOpenPreviewBlogModal(false);
                    }
                }}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                }}
            >
                <Box
                    sx={{
                        bgcolor: 'white',
                        width: '75%',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        borderRadius: '8px',
                        padding: '16px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        overflowX: 'hidden'
                    }}>



                    {isPreviewLoading ? (
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <div style={{ position: 'absolute', top: '16px', left: '16px', cursor: 'pointer' }}>
                                <CloseIcon
                                    sx={{ color: '#000' }}
                                    onClick={() =>{ 
                                        if(!isPreviewLoading && !isLoading){
                                        navigate('/codegenie/generated-blogs', { state: { FromDashboard: "true" } });
                                    }else{
                                        toast.info('please wait until the blog generation complete.');
                                    }
                                }}
                                />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto', maxHeight: '690px', overflow: 'hidden' }}>

                                {firstBlogData?.data[0] && (
                                    <>
                                        <img className="img-fluid auto-resize card-img-top" alt={"Code Genie:" + (firstBlogData.data[0].blog_title_draft)} style={{ maxHeight: '500px', objectFit: 'cover', objectPosition: 'center top', width: 'auto' }} src={(firstBlogData.data[0].mode === 'draft' ? firstBlogData.data[0].blog_image_path_draft : firstBlogData.data[0].blog_image_path)} title={"Code Genie:" + (firstBlogData.data[0].blog_title_draft)} />

                                    </>
                                )}

                            </div>

                            <Typography
                                variant="h3"
                                color="black"
                                fontWeight={600}
                                textAlign="center"
                                mb={10}


                            >
                                {firstBlogData?.data[0] && (
                                    <>
                                        {firstBlogData.data[0].blog_title_draft ? firstBlogData.data[0].blog_title_draft: firstBlogData.data[0].blog_title}

                                    </>
                                )}
                            </Typography>

                            <div style={{ marginLeft: '35px' }} className="author-details d-inline-block">
                                <div style={{ float: 'left' }}>
                                    {firstBlogData?.data[0] && (
                                        <>

                                            {firstBlogData.data[0]?.author_image && (
                                                <img className="ellipse-18" alt="Ellipse" style={{ height: 40, width: 30, alignItems: "flex-end" }} src={firstBlogData.data[0].author_image} title="Author Photo" />
                                            )
                                            }
                                            &nbsp; <a href={firstBlogData.data[0].author_url ? firstBlogData.data[0].author_url : firstBlogData.data[0].cta_url} target='_blank'>{firstBlogData.data[0].author}</a>, Published on: {firstBlogData.data[0].publish_date}
                                        </>
                                    )}

                                </div>
                            </div>
                            <br /><br />

                            <Typography
                                variant="h6"
                                color="black"
                                fontWeight={300}
                                textAlign="justify"
                                mb={0}

                            >
                                {firstBlogData?.data[0] && (
                                    <div>
                                        {firstBlogData.data[0].blog_summary_draft ? (
                                            <div dangerouslySetInnerHTML={{ __html: firstBlogData.data[0].blog_summary_draft }} />
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: firstBlogData.data[0].blog_summary }} />
                                        )}
                                    </div>
                                )}
                            </Typography>

                            <Typography
                                variant="h6"
                                color="black"
                                fontWeight={300}
                                textAlign="justify"
                                mb={ 0}

                            >
                                {firstBlogData?.data[0] && (
                                    <div>
                                        {firstBlogData.data[0].blog_det_draft ? (
                                            <div dangerouslySetInnerHTML={{ __html: firstBlogData.data[0].blog_det_draft }} />
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: firstBlogData.data[0].blog_det }} />
                                        )}
                                    </div>
                                )}
                            </Typography>
                        </>)}
                    {isLoading && (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            marginLeft: '12%',
                        }}>
                            <div style={{
                                width: '75%',
                                borderRadius: '5px',
                                textAlign: 'center',
                                marginTop: '6%',
                            }}>
                                <h4 style={{ textAlign: 'center', color: '#000', fontWeight: '300px' }}>Creating Blogs with Code Genie Ai Agents. This could take a couple of minutes …</h4>
                                <br />
                                <LinearProgress color="primary" />
                            </div>
                        </div>
                    )}
                </Box>
            </Modal> */}
            {/*  */}

            <Modal
                open={openPaidModal}
                onClose={() => setOpenPaidModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <p id="child-modal-description">Sorry! you can Generate a blog only after paid.</p>
                    <Stack flexDirection="row">
                        <Box sx={{ display: "inline-block", margin: "0 10px" }}>
                            <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
                        </Box>

                        <Button
                            onClick={() => {
                                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                                setOpenPaidModal(false);
                            }}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default Dashboard_tm;