import { useEffect, useState, ChangeEvent } from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  SvgIcon,
  TextField,
  Button,
  Grid,
  Checkbox,
  Autocomplete,
  FormControl,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Modal
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from "@mui/lab";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { object, string, TypeOf, array, number, z, unknown, nullable } from "zod";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import img54 from '../assets/newimg/logo.png';
import {
  useGetPaymentListQuery
} from "../redux/api/paymentApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { IPaymentDetails, IPaymentHistory } from "../redux/api/types";
import { useGetUserDetailsMutation, useUpdateSettingMutation } from "../redux/api/authApi";
import { logout } from "../redux/features/userSlice";
import {
  useGetUserDetailsQuery
} from "../redux/api/genieApi";
import { useGetPricesDetailsQuery } from "../redux/api/productApi";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { useUnSubscribepaymentMutation } from "../redux/api/authApi";
import axios from 'axios';
import { ReactComponent as VerifiedIcon } from '../assets/verified_ico.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import { usePlanDetailsMutation } from '../redux/api/productApi';
import { getCookie, removeCookie } from "utils/storageUtils";
import InfoIcon from '@mui/icons-material/Info';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#D0D5DD',
    color: 'lightblack',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white', // Set background color for odd rows to white
    color: 'black', // Set text color for odd rows to black
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F2F6FA', // Set background color for even rows to gray
    color: 'darkslategray',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const updateSettingform = object({
  user_image: string(),
  social_url: string(),
  blog_default: number(),
  email: string(),
  name: string()
});

let newErrors = {
  name: ''
};

const customInputStyle = {
  color: 'black',
  border: newErrors.name ? '1px solid red' : '1px solid gray',
};


export type updateSettingVal = TypeOf<typeof updateSettingform>



const Usersetting = () => {


  // console.log('isMobile=>', isMobile);
  // console.log('MobileView=>', MobileView);

  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("logged_in");

    if (loggedIn !== "true") {
      navigate("/signin");
    }
  }, [navigate]);

  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [planDetails, setPlanDetails] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState("Change Password");
  const useremail = localStorage.getItem('user_email') || getCookie('user_email') || '';
  const [userDetails, setUserDetails] = useState<any>('');
  const [updateSettingVal, setUpdateSettingVal] = useState<updateSettingVal>({ user_image: '', social_url: '', blog_default: 0, email: encodeURIComponent(useremail), name: '' })
  const [isLoading, setIsLoading] = useState(false);
  const [iscnameLoading, setIsCnameLoading] = useState(false);


  const [userImage, setUserImage] = useState<File | null>(null);
  const [userImageUrl, setUserImageUrl] = useState('');
  const [socialUrl, setSocialUrl] = useState('');
  const [blogDefault, setBlogDefault] = useState(true);
  const [name, setName] = useState('');
  const [getUserDetailsMutation] = useGetUserDetailsMutation();
  const [isChecked, setIsChecked] = useState(0);
  const [cnameRec, setCnameRec] = useState<any>();
  const [cnameDet, setCnameDet] = useState<any>();
  const [cancellingLoader, setCancellingLoader] = useState<boolean>(false);
  const [successCancelSubscription, setSuccessCancelSubscription] = useState(false);
  const [getuserPlanDetails] = usePlanDetailsMutation();
  const [userplanDetails, setuserPlanDetails] = useState<any>();
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);

  const validateForm = () => {

    // let isBlogDefaultRequired = false; 
    newErrors.name = "";

    if (!name || name.trim() === '') {
      newErrors.name = 'Name is required';
      toast.error("Name is required");
    }

    return Object.values(newErrors).every((error) => error === '');
  };


  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
    if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
      navigate("/Anycode_landing");
    else
      navigate("/");
    toast.success("Logout success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("user_email");
    // localStorage.removeItem("userRole");

    removeCookie('user_email');
    localStorage.clear();
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const checkedString = checked ? 1 : 0;
    setUpdateSettingVal(prevState => ({
      ...prevState,
      [name]: checkedString,
    }));
  };


  const fetchData = async () => {
    try {
      const response = await getUserDetailsMutation({ email: useremail });

      if ('data' in response) {
        // const datas = response.data.data;
        setUserDetails(response.data.data);

      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: useremail, campaignid: /* campIdFrEdit || localStorage.getItem('IdChangingMode') ||  */'' });
      if ('data' in response) {

        setuserPlanDetails(response.data.data);
        console.log('plan details***=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPlanDatas();
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUserImage(e.target.files[0]);
    }
  };

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked ? 1 : 0);
  }

  const handleUpdateSetting = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formData = new FormData();

    let isFormValid = validateForm();
    if (isFormValid) {
      setIsLoading(true);
      if (userImage) {
        formData.append('user_image', userImage);
      }
      formData.append('social_url', socialUrl);
      formData.append('blog_default', blogDefault ? '1' : '0');
      formData.append('name', name);
      formData.append('email', useremail);
      formData.append('auto_publish', isChecked ? `${isChecked}` : "0");

      try {
        const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
        const response = await axios.post(apiUrl + '/api/auth/updateUserSettings', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.status == "success") {
          toast.success(response.data.message);
          setIsLoading(false);
          const randomNumber = Math.random();
          setUserImageUrl(response.data.user_image + "?" + randomNumber);
        }
        else {
          toast.error("Somethng went wrong..")
          setIsLoading(false);
        }
        console.log(response.data);
      } catch (error) {
        console.error('Error updating user settings:', error);
        setIsLoading(false);
      }

    }

  };

  useEffect(() => {
    fetchData();
  }, [])


  useEffect(() => {
    // Update the name state when userDetails changes
    setName(userDetails.name);
    setSocialUrl((userDetails.social_url != "undefined" && userDetails.social_url) ? userDetails.social_url : "");
    setBlogDefault(userDetails.blog_default);
    setUserImageUrl(userDetails.user_image);
    setIsChecked(userDetails.auto_publish);
  }, [userDetails]);

  const fetchCnameRec = async () => {
    setIsCnameLoading(true);
    try {
      const domain = window.location.origin;
      const url = new URL(domain);
      const hostname = url.hostname;

      const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
      const response = await axios.post(apiUrl + `/api/prompts/getcnameentries?email=${encodeURIComponent(useremail)}&domain=${domain}&hostname=${hostname}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Cname Record:', response.data);
      setCnameDet(response.data);
      setCnameRec(response.data.cnamerecords);
    } catch (error) {
      console.log('Error fetching cname rec:', error);
    } finally {
      setIsCnameLoading(false);
    }
  }

  /*  useEffect(() => {
       fetchCnameRec();
   }, []); */

  const handleCopyName = (name: string) => {
    navigator.clipboard.writeText(name).then(() => {
      toast.success('Copied to Clipboard!');
    })
  }

  const handleCopyVal = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success('Copied to Clipboard!');
    })
  }

  const handleChangePayMethodClick = async () => {
    // localStorage.setItem('ChangepayMode', 'true');
    // localStorage.setItem('IdChangingMode', campIdFrEdit);
    try {
      const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
      const response = await axios.post(apiUrl + `/api/subscription/change_payment_method?email=${useremail}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status === 'success') {
        window.open(response.data.portal_url, '_blank');
      }
    } catch (error) {
      console.log('Error changing payment method', error);
    }
  }

  const handleCancelSubscriptionClick = async () => {
    setCancellingLoader(true);
    try {
      const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
      const response = await axios.post(apiUrl + `/api/subscription/Cancel_Subscription?email=${useremail}`, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      });
      if (response.data.status === 'success') {
        setSuccessCancelSubscription(true);
        toast.success('Cancelled Subscription!');
      }
    } catch (error) {
      toast.error('Error cancelling subscription');
      console.log('Error cancelling subscription', error);
    } finally {
      setCancellingLoader(false);
    }
  }

  return (
    <>
      {/* <div className="body-17-1"> */}

      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-md">
            <div className="container-fluid" style={{ padding: '0% 3%' }}>
              <a className="navbar-brand" href="/">
                {/* <img src="./img/logo.png" alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="">
                  <i className="bi bi-list text-white" />
                </span>
              </button>




              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                  <li className="nav-item">
                    {/* <a
                      className="nav-link nav_active"
                      style={{ color: "#4ba5eb", fontWeight: 800 }}
                      href="/"
                    >
                      Home
                    </a> */}
                  </li>

                </ul>


                {isMobile ?

                  <div className="d-flex gap-4" role="search" style={{ justifyContent: 'center' }}>
                    {/* <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("dashboard");
              }}>
                  Dashboard
                </button> */}
                    <button className="button_leniar px-3"
                      onClick={handleLogout} style={{ fontSize: '13px' }}>Logout</button>
                    <button className="button_leniar px-3"
                      onClick={(e) => { window.history.back(); return false; }} style={{ fontSize: '13px' }}>Go Back</button>
                  </div>
                  : <div className="d-flex gap-4" role="search">
                    {/* <button className="button_leniar px-3" type="submit"  onClick={() => {
  navigate("dashboard");
}}>
    Dashboard
  </button> */}
                    <button className="button_leniar px-3"
                      onClick={handleLogout} style={{ fontSize: '13px' }}>Logout</button>
                    <button className="button_leniar px-3"
                      onClick={() => window.history.back()} style={{ fontSize: '13px' }}>Go Back</button>
                  </div>}

              </div>
            </div>
          </nav>
          <hr className="" style={{ color: "rgb(154, 160, 163)" }} />
        </div>
      </div>
      <form id="sform" name="sform" onSubmit={handleUpdateSetting}>
        <Box padding={2}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              background:
                "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.8px",
              fontWeight: "bold",
            }}
          >
            Settings
          </Typography>
        </Box>
        <Container>
          <Stack>
            <Box
              padding={2}
              border="1px solid #CACBCC"
              borderRadius={1}
              component="div"

            >


              <Grid container style={{ borderRadius: '5px' }}>
                <Grid item xs={12} md={4} lg={4} style={{ padding: '1%' }}>


                  <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center' }}>
                    <Typography color="#CBCBCB" textAlign={'left'}
                      variant="h6"
                      fontWeight={600}>{/* User Details */}Ocesha</Typography>
                    <Typography color="#CBCBCB" textAlign={'left'}
                      variant="h6"
                      fontWeight={300}>You can view or update your details here</Typography>
                  </Grid>
                  {/* <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'left' }}><Typography><Checkbox
                    checked={isChecked === 1}
                    onChange={handleCheckBoxClick}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox' }}
                  />Automatically publish newly created blogs. This option will automatically publish andindex your blogs when they are created monthly. Uncheckthe box to manually publish your blog pages.</Typography>

                  </Grid> */}


                  <br></br>

                </Grid>

                <Grid item xs={12} md={8} lg={8} style={{ padding: '1%' }}>

                  <Box style={{ backgroundColor: '#F9FBFC', padding: '3%', borderRadius: '5px' }}>


                    <Stack>
                      <Grid container paddingY={'1%'}>

                        <Box sx={{ textAlign: 'left', gap: '10px', display: 'flex', marginLeft: /* planDetails?.mode === 'Canceled' ? '65%' : */ '44%' }}>
                          {/* {planDetails?.mode !== 'Paid' || planDetails?.payment_status !== 'paid' ? '' : */}
                          <Button variant='contained' sx={{
                            color: '#000', fontWeight: 'bold', '&.Mui-disabled': {
                              color: '#000',
                              backgroundColor: '#CBCBCB',
                              cursor: 'not-allowed'
                            }
                          }} onClick={handleCancelSubscriptionClick} disabled={userDetails?.payment_status === 'Canceled'/* successCancelSubscription */}>{userDetails?.payment_status === 'Canceled' ? 'Cancelled' : 'Cancel Subscription'}</Button>
                          {/* } */}
                          {/* {planDetails?.mode !== 'Paid' || planDetails?.payment_status !== 'paid' ? '' : */} <Button variant='contained' sx={{ color: '#000', fontWeight: 'bold' }} onClick={handleChangePayMethodClick}>Change Payment Method</Button>{/* } */}
                        </Box>
                      </Grid>

                      <Grid container paddingY={'1%'}>

                        {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>Name</Typography>
                        {/* </Grid> */}
                        {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField

                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="text"
                          placeholder="Enter your name here."
                          value={name}
                          onChange={(e) => setName(e.target.value)}

                        />
                        {newErrors.name && <p style={{ color: 'red', fontSize: '12px' }}>{newErrors.name}</p>}
                      </Grid>

                      <Grid container paddingY={'1%'}>
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>Upload your profile image</Typography>

                        <input
                          type="file"
                          accept="image/*"
                          //onChange={handleImageChange}
                          style={{ width: '100%' }}
                          onChange={handleImageChange}
                        />
                        {userImageUrl && (
                          <img src={`${userImageUrl}?${Date.now()}`} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                        )}
                        {/* {Errors.user_image && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.user_image}</p>} */}
                        {/* </Grid> */}
                      </Grid>

                      <Grid container paddingY={'1%'}>
                        {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>URL</Typography>
                        {/* </Grid> */}
                        {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField
                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="text"
                          placeholder="E.g. https://www.linkedin.com/in/profile_name"
                          value={socialUrl}
                          onChange={(e) => setSocialUrl(e.target.value)}
                        //value={decodeURIComponent(updateSettingVal.social_url??'')}
                        // onChange={(e) => setUpdateVal({ ...updateVal, passwordConfirm: e.target.value })}
                        //onChange={(e) => setUpdateSettingVal({ ...updateSettingVal, social_url: encodeURIComponent(e.target.value) })}
                        // onChange={handleChange}
                        />
                        {/* {Errors.social_url && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.social_url}</p>} */}
                        {/* </Grid> */}
                      </Grid>

                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={(updateSettingVal.blog_default === 1)}
                            name="blog_default"
                            checked={blogDefault}
                            onChange={(e) => setBlogDefault(e.target.checked)}
                            style={{ color: 'black', borderColor: 'black' }}
                          />
                        }
                        label={<Typography color="black">Use this information on my blogs</Typography>}
                      />
                      {/* {Errors.blog_default && <p style={{ color: 'red', fontSize: '10px' }}>{Errors.blog_default}</p>} */}

                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked === 1}
                            onChange={handleCheckBoxClick}
                            color="primary"
                            inputProps={{ 'aria-label': 'checkbox' }}
                            style={{ color: 'black', borderColor: 'black' }}
                          />
                        }
                        label={<Typography color="black">Automatically publish newly created blogs.</Typography>}
                      />
                      <Typography color="black">This option will automatically publish and index your blogs when they are created monthly. Uncheck the box to manually publish your blog pages.</Typography> */}

                      <Grid container style={{ paddingTop: '1%', paddingBottom: '1%', display: 'flex', justifyContent: 'center' }}>
                        {/* <Grid item xs={12} md={9} lg={9} style={{ padding: '1%' }}> */}
                        {/* <Grid item xs={12} md={6} lg={6} style={{ display: 'flex', paddingTop: '1%', paddingBottom: '1%' }}>
                        <Button variant="outlined" style={{ marginRight: '1%', width: '100%' }} >Cancel</Button>
                      </Grid> */}
                        <Grid item xs={12} md={6} lg={6} style={{ display: 'flex', paddingTop: '1%', paddingBottom: '1%' }}>

                          <Button type="submit" variant="contained" style={{ marginRight: '1%', width: '100%', backgroundColor: '#0076AD', color: '#FFF' }}>{isLoading ? <CircularProgress /> : 'Update'}</Button>
                        </Grid>
                        {/* */}

                        {/* </Grid> */}
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>


              </Grid>

              <Divider />
              {/* {isBrowser ?
                <TableContainer component={Paper} sx={{ margin: '1%', width: '98%' }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">



                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell colSpan={6} style={{ color: 'black', border: "darkgray" }} align="center">You can use CNAME pointing to our domain "www.trafficmonster.ai" or "images.code-genie.ai"
                          <br />Using CNAME entry you can acces all our blogs using your domain.<br />
                          E.g<br />Create a domain "subdomain.yourdomain.com" and add CNAME entry with value "www.trafficmonster.ai" or "images.code-genie.ai" in your DNS records.<br />
                          Now you can access the blog using the url https://subdomain.yourdomain.com/blogs/myfirstblog <br />
                          <br /><p><h3>How to set up a CNAME with GoDaddy</h3></p>

                          <p>Your custom domain is activated immediately, but for the mapping to be effective, you have to add the right CNAME in your domain’s DNS records. How you add a CNAME to your DNS records depends on your hosting service.
                            <br />
                            <br />Here’s a brief guide to setting up a CNAME with GoDaddy:</p><br />
                          <p>
                            1. Sign in to your GoDaddy account and go to the My Products tab.<br />

                            2. Click the domain you want to update.<br />

                            3. Click Domain Settings.<br />

                            4. In the Additional Settings section, click Manage DNS.<br />

                            5. On the DNS Management page, in the Records section, click Add.<br />

                            6. From the Type drop-down list, choose CNAME.<br />

                            7. In the Host field, type the respective subdomain (i.e., elearning if you’re using elearning.mysite.com for your TrafficMonster portal).<br />

                            8. In the Points to field, type your TrafficMonster domain (www.trafficmonster.ai).<br />

                            9. Leave the TTL setting as 1 hour.<br />

                            10. Click Save.<br />
                          </p><br />
                          <p><b>Note:</b> Your changes may take up to 48 hours to go live. These DNS settings may vary little bit for different providers.</p>
                        </StyledTableCell>
                      </StyledTableRow>


                    </TableBody>

                  </Table>
                </TableContainer>
                : null} */}
              {/* {localStorage.getItem('subscription_level') === 'level_3' || localStorage.getItem('subscription_level') === 'level_6' ? */}
              <TableContainer component={Paper} sx={{ margin: '1%', width: '98%', overflowX: 'auto', border: '1px solid gray', background: 'none' }}>
                <Table sx={{ minWidth: 700, width: '100%' }} aria-label="customized table">
                  <TableHead style={{ backgroundColor: ' #D0D5DD', border: "gray" }} onClick={() => {
                    if (userplanDetails?.plan_name === 'Enterprise') {
                      fetchCnameRec();
                    } else {
                      setUpgradeModal(true);
                    }
                  }}>
                    <TableRow>
                      <StyledTableCell style={{ border: "gray", width: "100%", color: '#344054', fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }} align="center">Configure DNS<br /><span style={{ color: '#626F86', fontSize: '16px', fontWeight: 'lighter' }}>You will need to add a CNAME to your domain in order for the blog to be used on your domain.<br /> Please copy the below CNAME data and add in your DNS entries.<br />We will auto verify once the domain is configured.</span><span style={{ display: 'flex', justifyContent: 'right' }}><Button variant="contained" style={{ backgroundColor: '#0076AD', color: '#FFF' }} onClick={fetchCnameRec}>{iscnameLoading ? <CircularProgress size={24} /> : <><RefreshIcon />Refresh</>}</Button></span></StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                {userplanDetails?.plan_name === 'Enterprise' ?
                  <>
                    {iscnameLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                      <CircularProgress size={60} />
                    </div> :
                      <Table sx={{ minWidth: 700, width: '100%', marginTop: '4%' }} aria-label="customized table">
                        <TableHead style={{ backgroundColor: ' #EAECF0', border: "gray" }}>
                          <TableRow>
                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">DOMAIN NAME</StyledTableCell>
                            <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">RECORD TYPE</StyledTableCell>
                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">HOST NAME</StyledTableCell>
                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">REQUIRED VALUE</StyledTableCell>
                            <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">VERIFY</StyledTableCell>
                            {/* <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">ACTION</StyledTableCell> */}

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cnameRec && cnameRec.map((record: any, index: any) => (
                            <StyledTableRow>
                              <StyledTableCell style={{ width: '20%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{cnameDet.mydomain_name}</StyledTableCell>
                              <StyledTableCell style={{ width: '10%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>CNAME</StyledTableCell>
                              <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  height: 45
                                },
                              }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        size="small"
                                        sx={{
                                          marginRight: '4px',
                                          background: '#000',
                                          borderRadius: '3px',
                                          padding: '10%',
                                          width: '120%',
                                          fontSize: '14px',
                                          '&:hover': {
                                            background: '#000', // Optional: Add hover effect for better UX
                                          },
                                        }}
                                        onClick={() => handleCopyName(record.name)}
                                      >
                                        Copy
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                value={record.name}></TextField></StyledTableCell>
                              <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#9FADBC',
                                  },
                                  height: 45
                                },
                              }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        size="small"
                                        sx={{
                                          marginRight: '4px',
                                          background: '#000',
                                          borderRadius: '3px',
                                          padding: '10%',
                                          width: '120%',
                                          fontSize: '14px',
                                          '&:hover': {
                                            background: '#000', // Optional: Add hover effect for better UX
                                          },
                                        }}
                                        onClick={() => handleCopyVal(record.value)}
                                      >
                                        Copy
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                value={record.value}></TextField></StyledTableCell>
                              <StyledTableCell style={{ width: '15%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{cnameDet.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', padding: '5%', textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '16px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', padding: '5%', textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '16px' }}>Non-verified</span></div>}</StyledTableCell>
                              {/* <StyledTableCell style={{ width: '15%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><div style={{ border: '1px solid #D43131', color: '#D43131', fontSize: '14px', padding: '5%', textAlign: 'center', borderRadius: '5px' }}>Remove Domain</div></StyledTableCell> */}

                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    }
                  </> : ''}
              </TableContainer>
              {/* : ''} */}

            </Box>
          </Stack >
        </Container >
        {/* </div> */}
      </form>
      <footer>
        <div className="py-5 mx-sm-0 mx-2 backgroundbg">
          <div className="container">
            <div className="row px-sm-0 px-2">
              {/* <div className="col-lg-3 text-white text-lg-start text-center">
            <div>
              <div>
                <img src={img54} className="img-fluid" alt="" />

              </div>
              <p className="fw_samibold">
                OceSha/Code Genie Los Angeles, Ca 310-748-8901
              </p>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
            <div>
              <h6 className="f_heading mb-4">
                Get news in your mailbox right away!
              </h6>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Information</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                      <a target="_blank" href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                    </li>
                    <li>
                      <a target="_blank" href="/contactus">Contact us</a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Support</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                      <a target="_blank" href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-block d-none">
            <div>
              <h5 className="mb-4 f_heading">
                Get news in your mailbox right away!
              </h5>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            News Subscribe
         <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div>
                  //
          </div> */}
            </div>
          </div>
        </div>
      </footer>

      <Modal
        open={upgradeModal}
        /* onClose={(event, reason) => {
            if (reason !== "backdropClick") {
                setHelperModal(false);
            }
        }} */
        disableEscapeKeyDown
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: '15px',
            padding: '3%'
          }}
        >
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />This feature is not included in your plan.  Please upgrade to have access to this feature.</Typography>

          <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>
            {/* 
                        <Button
                          variant="contained"
                          onClick={() => {
                            const value = {
                              _id: "",
                              product_name: "CodeGenie",
                              product_module: genieSelector.module ? genieSelector.module : "",
                              userid: "",
                              productid: ""
                            };
                            getProduct(value);
                            localStorage.setItem('fromUpgrade', 'true');
                            navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'blog_listing' } });
                          }}>
                          Upgrade
                        </Button> */}

            <Button
              onClick={() => setUpgradeModal(false)}
              variant="contained"
            // sx={{ mr: 2, marginTop: '15%' }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>

    </>
  );
};

export default Usersetting;