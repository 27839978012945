// src/utils/storageUtils.ts

export const setCookie = (name: string, value: string): void => {
    document.cookie = `${name}=${encodeURIComponent(value)};path=/`;
};

export const getCookie = (name: string): string | null => {
    const cookieArr = document.cookie.split(';');
    for (let cookie of cookieArr) {
        cookie = cookie.trim();
        if (cookie.startsWith(`${name}=`)) {
            return decodeURIComponent(cookie.split('=')[1]);
        }
    }
    return null;
};

export const removeCookie = (name: string): void => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
};

export const saveToLocalStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key);
};

export const removeFromLocalStorage = (key: string): void => {
    localStorage.removeItem(key);
};
