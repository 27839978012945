import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img54 from '../../assets/newimg/logo.png';
import logo_white from '../../assets/logo_white.png';
import { ReactComponent as TickIcon } from "../../assets/tick_ico.svg";
import { ReactComponent as RoundedRightArrow } from '../../assets/right_rounded_arrow.svg';
import { isMobile } from "react-device-detect";
import rohanImg from '../../assets/traffic_monster/rohanImg.png';
import { ReactComponent as RightArrowIcon } from '../../assets/right_arrow.svg';
import { TextField, Typography, CircularProgress } from "@mui/material";
import oracle from '../../assets/traffic_monster/oracle.png';
import hp from '../../assets/traffic_monster/hp.png';
import honda from '../../assets/traffic_monster/honda.png';
import american_red_cross from '../../assets/traffic_monster/american_red_cross.png';
import nv_energy from '../../assets/traffic_monster/nv_energy.png';
import avery_dennison from '../../assets/traffic_monster/avery_dennison.png';
import corning from '../../assets/traffic_monster/corning.png';
import neural_network from '../../assets/traffic_monster/neural_network.png';
import Cpu from '../../assets/traffic_monster/Cpu.png';
import '../traffic_monster/generated_blogs.css';
import export_search_engine_optimization from '../../assets/traffic_monster/Search_engine_optimization.png';
import frames_with_media_content from '../../assets/traffic_monster/frames_with_media_content.png';
import frame_tick from '../../assets/traffic_monster/frame_tick.png';
import Rectangle_92 from '../../assets/traffic_monster/Rectangle_92.png';
import Rectangle_93 from '../../assets/traffic_monster/Rectangle_93.png';
import Rectangle_94 from '../../assets/traffic_monster/Rectangle_94.png';
import Rectangle_95 from '../../assets/traffic_monster/Rectangle_95.png';
import Rectangle_96 from '../../assets/traffic_monster/Rectangle_96.png';
import marketing_strategy from '../../assets/traffic_monster/marketing_strategy.png';
import marketing_strategy_mbv from '../../assets/traffic_monster/marketing_strategy_mbv.png';
import testimonial_rating from '../../assets/traffic_monster/testimonials_rating.png';
import testimonial_videoImg1 from '../../assets/traffic_monster/testimonial_videoImg1.png';
import testimonial_videoImg2 from '../../assets/traffic_monster/testimonial_videoImg2.png';
import Slider from "react-slick";
import TestimonialItem from '../../components/TestimonialItem';
import ArrowCircleLeft from '../../assets/traffic_monster/ArrowCircleLeft.png';
import ArrowCircleRight from '../../assets/traffic_monster/ArrowCircleRight.png';
import testimonialImg1 from '../../assets/traffic_monster/testimonialImg1.png';
import testimonialImg2 from '../../assets/traffic_monster/testimonialImg2.png';
import testimonialImg3 from '../../assets/traffic_monster/testimonialImg3.png';
import equip_your_kids from '../../assets/traffic_monster/equip_your_kids.png';
import signup_frame from '../../assets/traffic_monster/signup_frame.png';
import AI_wealth_mastery_course from '../../assets/traffic_monster/AI_wealth_mastery_course.png';
import AI_fundamental_course from '../../assets/traffic_monster/AI_fundamental_course.png';
import AI_custom_software_dev_course from '../../assets/traffic_monster/AI_custom_software_dev_course.png';
import testimonialImg4 from '../../assets/traffic_monster/testimonialImg4.jpg';
import { Link } from "react-router-dom";
import { useGetgeneratedBlogListingsQuery, useGetrandomBlogListingsQuery } from '../../redux/api/promptApi';
import testimonial_paul_video from '../../assets/traffic_monster/Paul_Claxton_testimonial_vid.mp4';
import { ReactComponent as TickIco } from '../../assets/traffic_monster/tick_icon.svg';
import TimeManagement from '../../assets/traffic_monster/Time management.png';
import techblueprint from '../../assets/traffic_monster/Technical blueprints and architectural research.png';
import userfeedback from '../../assets/traffic_monster/User feedback.png';
import coinsIcon from '../../assets/traffic_monster/coins icon.png';
import { ReactComponent as LinkIcon } from '../../assets/podcast_monster/link.svg';
import podcastImg from '../../assets/podcast_monster/bg0.jpg';
import howWorks from '../../assets/podcast_monster/bg1.png';
import key_features from '../../assets/podcast_monster/key_value_new_img.png';
import key_feature_1 from '../../assets/podcast_monster/key_feature_2.png';
import key_feature_2 from '../../assets/podcast_monster/key_features_new_1.png';
import key_feature_3 from '../../assets/podcast_monster/key_features-New_2.png';
import key_feature_4 from '../../assets/podcast_monster/key_feature_4.png';
import common_bg from '../../assets/podcast_monster/common_bg.png';
import { ReactComponent as LineIcon } from '../../assets/podcast_monster/vertical_line.svg';
import { ReactComponent as ArrowIcon } from '../../assets/podcast_monster/down_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/podcast_monster/right_arrow.svg';
import customization_options from '../../assets/podcast_monster/customization_section.png';
import { ReactComponent as ArrowBulleting1 } from '../../assets/podcast_monster/arrow_bulleting1.svg';
import { ReactComponent as ArrowBulleting2 } from '../../assets/podcast_monster/arrow_bulleting2.svg';
import { ReactComponent as ArrowBulleting3 } from '../../assets/podcast_monster/arrow_bulleting3.svg';
import getting_started from '../../assets/podcast_monster/getting_started.png';
import faq_robo from '../../assets/podcast_monster/faq_robo.png';
import chat_faq from '../../assets/podcast_monster/chat_faq.png';
import { ReactComponent as CopyRightIcon } from '../../assets/podcast_monster/copyrights_icon.svg';
import { ReactComponent as BulletVerticalIcon } from '../../assets/podcast_monster/bulleting_line.svg';
import { ReactComponent as SquareBulleting } from '../../assets/podcast_monster/square_bulleting.svg';
import { ReactComponent as SquareBulleting1 } from '../../assets/podcast_monster/square_bulleting1.svg';
import { ReactComponent as SquareBulleting2 } from '../../assets/podcast_monster/square_bulleting2.svg';
import how_it_works from '../../assets/podcast_monster/how_it_works.png';
import { useGenerateBusinessBlogMutation } from '../../redux/api/promptApi';
import { toast } from "react-toastify";
import axios from 'axios';
import howitworks1 from '../../assets/podcast_monster/how_it_works_new_1.png';
import howitworks2 from '../../assets/podcast_monster/how_it_works_new_2.png';
import benefits_new from '../../assets/podcast_monster/benefit_bckgrnd_new.png';
import customizationOption from '../../assets/podcast_monster/customization_new.png';



const TrafficMonsLandingNew = () => {

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexTextTesti, setCurrentIndexTextTesti] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(false);
    const [isIpadMini, setIsIpadMini] = useState(false);
    const [randomInteger, setRandomInteger] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [blogs, setBlogs] = useState<any>();
    const [randomBlogs, setRandomBlogs] = useState<any>();
    const [httpsVal, setHttpsVal] = useState('https://');
    const [businessBlogMutation] = useGenerateBusinessBlogMutation();
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [otherOption, setOtherOption] = useState(false);
    const [uploadVideo, setUploadVideo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedvideopath, setUploadedvideopath] = useState('');
    const [arrowClick1, setArrowClick1] = useState<any>();
    const [arrowClick2, setArrowClick2] = useState<any>();
    const [arrowClick3, setArrowClick3] = useState<any>();

    const sections = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.2, // threshold based on when want the animation to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible'); // Add 'visible' class when in view
                } else {
                    entry.target.classList.remove('visible'); // Remove class when out of view
                }
            });
        }, observerOptions);

        sections.current.forEach((section) => {
            if (section instanceof Element) {
                observer.observe(section);
            }
        });

        return () => {
            sections.current.forEach((section) => {
                if (section instanceof Element) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    const promptid = params.get('promptid') ?? "";

    const videotestimonialData = [
        {
            // image: testimonial_videoImg1,
            video: "https://www.youtube.com/embed/mEyC24nC73k",
            name: "Jay Levin",
            title: "CEO Equip Our Kids LA Weekly Magazine"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/bQ694TD-QCE",
            name: "Giovanni Villa",
            title: "CEO Bella2Bello"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/KLLaclzl5r0",
            name: "Paul Claxton",
            title: "Managing General Partner"
        }

    ];
    const testimonialCount = videotestimonialData.length;
    /* video testimonial */
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 3 + testimonialCount) % testimonialCount);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonialCount);

    };

    const getDisplayedTestimonials = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(videotestimonialData[(currentIndex + i) % testimonialCount]);
        }
        return displayedTestimonials;
    };

    const handlePrevMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNextMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex === videotestimonialData.length - 1 ? 0 : (isMobile ? prevIndex + 1 : 0)));
    };

    /*  */



    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
            setIsIpadMini(window.innerWidth <= 1024 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */

    /* Text testimonial */
    const testimonialtoshow = (isMobile) ? 1 : 3;

    var testimonialsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: testimonialtoshow,
        slidesToScroll: 1,
        arrows: true,
    };
    const testimonials = [
        {
            name: "CRAIG SHAH/CEO",
            icon: <img src={testimonialImg4} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Amazing!",
            reference: "EVOKE"
        },
        {
            name: "KURT KUMAR/CEO",
            icon: <img src={testimonialImg3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Traffic Monster solves a problem that every business encounters.  How do you get traffic and leads and ultimately sales to your business.",
            reference: "SAPX"
        },
        {
            name: "JAMAL WOODLEY/CEO",
            icon: <img src={testimonialImg1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "It's very impressive.  I think this is something that every entrepreneur will be excited about..",
            reference: "MINDMADE BRANDS"
        }
    ];
    const testimonialCount1 = testimonials.length;

    const handlePrevTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex - 1 + testimonialCount1) % testimonialCount1);
    };

    const handleNextTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex + 1) % testimonialCount1);
    };

    const getDisplayedTestimonials1 = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(testimonials[(currentIndexTextTesti + i) % testimonialCount1]);
        }
        return displayedTestimonials;
    };
    /*  */

    useEffect(() => {
        // Calculate random integer when totalPages is available
        if (totalPages !== null) {
            const min = 1;
            const max = totalPages;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
            setRandomInteger(randomInteger);
        }
    }, [totalPages]);

    /* News portion Blogs */
    const supportEmail: string = process.env.REACT_APP_SUPPORT_EMAIL || '';
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: ''/* promptid */, userid: ''/* supportEmail */, limitval: 3, skipval: randomInteger });

    useEffect(() => {
        if (getBlogs.data) {
            setBlogs([...getBlogs.data]);
        }
    }, [getBlogs]);
    /*  */

    /* random customer blogs */
    const getRandomBlogs = useGetrandomBlogListingsQuery({});

    useEffect(() => {
        if (getRandomBlogs.data) {
            setRandomBlogs([...getRandomBlogs.data]);
        }
    }, [getRandomBlogs]);
    /*  */

    // const domain = window.location.hostname;
    const handleReadMoreClick = (userid: any) => {
        window.open(`/blogs?userid=${userid}`, '_blank');
    }

    const supportUserid = process.env.REACT_APP_SUPPORT_USER_ID || '';

    const handleUnload = () => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    };
    window.addEventListener("beforeunload", handleUnload);

    useEffect(() => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    }, []);

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const clearLocalStorage = () => {
        const keysToRemove = ["msg", "complexity", "module", "subscription_level", "free_plan", "localStorage_msg", "allSet", "Blogs Generated", "remaining_wish_count", "userRole", "payment", "user_email", "logged_in", "prevImg", "fromUpgrade", "blogsCount", "Generating Count", "finalData", "user_pass", "fromNewcampaign", "campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption", "autoPublish", "limitPublish", "campId", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn", "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "owndomain", "ChangepayMode", "IdChangingMode", "user_name", "refreshCount", "publishingCampId", "Authorurl", "Authorname", "authorDefaultCheck", "appsumo_coupon_code", "podcastUrl", "AlertMessage", "CreatingfromLanding", "UrlfromLanding", "signedup", "uploadedvideo", "uploadedVideoName", "uploadStatus"];
        keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    const handleGenerateBlogClick = async (url: string, userid: string, uploadedvideo: string) => {
        clearCookies();
        clearLocalStorage();
        if (url) {
            let youtubeMatch = url.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?/
            );

            if (youtubeMatch) {
                localStorage.setItem('CreatingfromLanding', 'true');
                localStorage.setItem('UrlfromLanding', url);
                if (userid === '') {
                    navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding') } });
                }
            } else if (!youtubeMatch) {
                toast.error('Please enter valid youtube video url!');
            }
        } else if (localStorage.getItem('uploadStatus') == 'success' && uploadVideo) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('uploadedvideo', uploadedvideo);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), VideofromLanding: localStorage.getItem('uploadedvideo'), videoNamefromLanding: localStorage.getItem('uploadedVideoName') } });
            }
        } else if (!url || !uploadedvideo) {
            toast.error('Please enter your youtube link/upload video file!');
        }
    }

    const handlevideoChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUploadVideo(file.name);
            console.log('Uploaded video file:', file);
            const formData = new FormData();
            formData.append('media_file', file);

            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            setIsLoading(true);
            try {
                const response = await axios.post(
                    `${apiUrl}/api/prompts/upload_media_file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response.data.data);
                if (response.data.result === 'success' && response.data.data) {
                    localStorage.setItem('uploadedVideoName', file.name);
                    localStorage.setItem('uploadStatus', 'success');
                    setUploadedvideopath(response.data.data);
                    toast.success('File Uploaded Successfully!');

                    localStorage.setItem('CreatingfromLanding', 'true');
                    localStorage.setItem('uploadedvideo', response.data.data);
                    // if (localStorage.getItem('user_email') === '') {
                    navigate('/signup', {
                        state: {
                            CreatingfromLanding: localStorage.getItem('CreatingfromLanding'),
                            VideofromLanding: localStorage.getItem('uploadedvideo'),
                            videoNamefromLanding: localStorage.getItem('uploadedVideoName')
                        }
                    });
                    // }
                } else if (response.data.result === 'fail') {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                toast.error('Error uploading video');
            } finally {
                setIsLoading(false);
            }

        }
    };

    return (
        <>
            <div style={{ background: '#000' }}>


                {/* Header */}
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-md">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="/">
                                    {/* <img src="./img/logo.png" alt="" /> */}
                                    <img src={img54} className="img-fluid" alt="" />

                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarScroll"
                                    aria-controls="navbarScroll"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="">
                                        <i className="bi bi-list text-white" />
                                    </span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarScroll">
                                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link nav_active"
                                                style={{ color: "#4ba5eb", fontWeight: 800 }}
                                                href="/"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            localStorage.setItem('pricing_from_landing', 'true');
                                            navigate("/pricing");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Pricing
                                            </a>
                                        </li>
                                        {/*  <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup?revgems-session-id=45a7e1ae-a67a-4197-9973-f002c2338e37&_gl=1*159ev21*_gcl_au*MjI5MjQ3ODg2LjE3MTQ3MzU2NTE.*_ga*MTAyOTUzNzQyMC4xNzE0NzM1NjU0*_ga_YJYFH7ZS27*MTcxNTY3NjkxOC40LjEuMTcxNTY3NzIwMy41MS4wLjA">
                                            Affiliates
                                        </a>
                                    </li> */}
                                        {/* <li className="nav-item" style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                                                Affiliates
                                            </a>
                                        </li> */}

                                        <li className="nav-item" onClick={() => {
                                            navigate("/contactus");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Contact
                                            </a>
                                        </li>
                                        {/* <li className="nav-item" onClick={() => {
                                            window.open(`/blogs?userid=${supportUserid}`, '_blank');
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Blogs
                                            </a>
                                        </li> */}
                                    </ul>
                                    <div className="d-flex gap-4" role="search" style={{ justifyContent: isMobile ? 'center' : '' }}>
                                        <button className="btn btn-outline-primary px-3"
                                            onClick={() => {
                                                if (localStorage.getItem('logged_in') === 'true') {
                                                    navigate('/codegenie/generated-blogs', { state: { userEmail: localStorage.getItem('user_email') || '' } });
                                                } else {
                                                    navigate("/signin");
                                                }
                                            }}>Sign  In</button>
                                        <button className="button_leniar px-3" type="submit" onClick={() => {
                                            navigate("/signup");
                                        }}>
                                            {/* Try Now */}Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <hr className="mt-3" style={{ color: "#9aa0a3" }} />
                    </div>
                </div>
                {/*  */}

                <div className="container">

                    {/* Podcast Monster Section */}
                    <div
                        ref={(el) => sections.current[0] = el}
                        className="section podcast-monster-section"
                        style={{
                            // backgroundImage: `url(${podcastImg})`,
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >

                        <div className="row  mb-5">
                            <div className="col-12 text-white text-center" style={{ padding: '20px' }}>
                                <h2
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '46px',
                                        lineHeight: isMobile ? '1.4' : '1.6'
                                    }}
                                >
                                    PODCAST MONSTER
                                </h2>
                                <h2
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '33px',
                                        lineHeight: isMobile ? '1.4' : '1.6'
                                    }}
                                >
                                    Turn Your Podcast into a  <br /> Revenue Stream
                                </h2>
                                {/*  <button
                            className="button_leniar px-3 mt-3"
                            type="submit"
                            style={{
                                fontSize: '12px',
                                width: isMobile ? '70%' : '12%',
                                borderRadius: '20px',
                                padding: isMobile ? '10px' : '8px'
                            }}
                        onClick={() => {
                            navigate("/signup");
                        }}
                        >
                            TRY IT FREE
                        </button> */}
                            </div>
                            <div className="row" style={{ justifyContent: isMobile ? 'center' : '', paddingLeft: isMobile ? '0' : '' }}>
                                <div className="col-sm-12 col-md-1 col-lg-1 d-none">
                                    &nbsp;
                                </div>
                                <div className={/* isMobile ? "col-sm-12 col-md-10 col-lg-10 rounded-1" :  */"col-sm-12 col-md-10 col-lg-10 rounded-1 responsive-col-podcastsec"} style={{
                                    padding: '2%',
                                    borderRadius: '12px',
                                    width: isMobile ? '80%' : '85%',
                                    display: isMobile ? 'flex' : '',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    marginLeft: isMobile ? '9%' : '8%'
                                }}>
                                    {otherOption ? (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', border: '1px solid #CBCBCB', borderRadius: '5px' }}>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                style={{
                                                    width: '100%',
                                                    //  height: '200px',
                                                    borderColor: '#CBCBCB',
                                                    borderRadius: '5px',
                                                    padding: '10px'
                                                }}
                                                onChange={handlevideoChange}
                                            />

                                            {isLoading ? <CircularProgress size={35} style={{ marginRight: '1%' }} /> : null}
                                        </div>
                                    ) : (
                                        <TextField
                                            placeholder="Enter a YouTube link"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            style={{ height: '40px', background: '#131825' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <LinkIcon style={{ marginRight: '8px', width: '20px', height: '20px' }} />
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root::placeholder': {
                                                    color: '#98A2B3',
                                                    opacity: 1,
                                                },
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: '#98A2B3',
                                                    opacity: 1,
                                                },
                                            }}
                                            value={youtubeUrl}
                                            onChange={(e) => setYoutubeUrl(e.target.value)}
                                        />
                                    )}
                                    <Typography sx={{ color: '#4BA5EB', marginTop: '7px', cursor: 'pointer' }}
                                        onClick={() => {
                                            setOtherOption(!otherOption);
                                            localStorage.removeItem('CreatingfromLanding');
                                            localStorage.removeItem('UrlfromLanding');
                                            localStorage.removeItem('uploadedvideo');
                                            localStorage.removeItem('uploadedVideoName');
                                            localStorage.removeItem('uploadStatus');
                                        }}>
                                        or See other options
                                    </Typography>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="button_leniar px-3"
                                            type="submit"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '22px',
                                                fontWeight: 'bold',
                                                width: isMobile ? '70%' : '20%',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                filter: isLoading ? 'blur(2px)' : 'none',
                                                pointerEvents: isLoading ? 'none' : 'auto'
                                            }}
                                            onClick={() => handleGenerateBlogClick(youtubeUrl, localStorage.getItem('user_email') || '', uploadedvideopath)}
                                            disabled={isLoading}
                                        >
                                            Create Blog
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-1 col-lg-1 d-none">
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="mx-sm-0 mx-2" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                            <div className="col-12 rounded-1" style={{ width: '85%', padding: isMobile ? '4%' : '1%' }}>
                                <Typography style={{ textAlign: 'center', width: "100%", marginBottom: isMobile ? '14%' : '' }}>By Rohan Hall</Typography>
                                <div className="gap-3 justify-content-center" style={{ display: "flex", flexWrap: isMobile ? 'nowrap' : 'wrap', flexDirection: isMobile ? 'column' : 'row', width: "100%", justifyContent: "center", alignItems: "center", }}>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={oracle} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={hp} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={honda} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={american_red_cross} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={nv_energy} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={avery_dennison} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                    <div className="my-auto" style={{ flex: "0 0 auto", maxWidth: "150px", margin: "0px" }}>
                                        <img src={corning} className="img-fluid1" alt="" style={{ maxWidth: "100%", height: "auto" }} />
                                    </div>
                                </div>
                            </div>
                            <Typography style={{ textAlign: 'center', width: "100%", marginTop: isMobile ? '14%' : '4%', fontSize: '27px', color: '#fff' }}>Monetize and expand your podcast's reach with ease <br />using Podcast Monster's all-in-one solution.</Typography>
                            {/* <Typography style={{ textAlign: 'center', width: "100%", marginTop: isMobile ? '14%' : '2%', fontSize: '15px', color: '#fff' }}>Leverage your podcast content to grow your audience with AI-powered, personalized blogs. Podcast Monster<br />generates SEO-enhanced blogs that you can share, download, or post on your website—automatically.</Typography> */}
                        </div>

                    </div>
                    {/* <hr></hr> */}
                    {/* Podcast Monster Section End*/}

                    {/* Key Features Section */}
                    <div
                        ref={(el) => sections.current[1] = el}
                        className="section key-features-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-1 col-lg-1"></div>
                            <div className="col-sm-12 col-md-7 col-lg-7">
                                <h4 style={{ color: '#fff', fontSize: '33px' }}>Key Value Propositions</h4>
                                <div style={{ marginTop: '6%' }}>
                                    <img src={key_feature_1} />
                                    <p style={{ fontSize: '16px', color: '#fff', marginTop: '2%', fontWeight: 'bold' }}>Monetization Made Easy</p>
                                    <p style={{ fontSize: '15px', color: '#fff', marginTop: '2%' }}>Unlock new revenue streams by offering content creation and marketing services.</p>
                                </div>
                                <div style={{ marginTop: '6%' }}>
                                    <img src={key_feature_2} style={{ width: '37px' }} />
                                    <p style={{ fontSize: '16px', color: '#fff', marginTop: '2%', fontWeight: 'bold' }}>Wider Distribution</p>
                                    <p style={{ fontSize: '15px', color: '#fff', marginTop: '2%' }}>Expand your reach by sharing content on multiple platforms</p>
                                </div>
                                <div style={{ marginTop: '6%' }}>
                                    <img src={key_feature_3} style={{ width: '37px' }} />
                                    <p style={{ fontSize: '16px', color: '#fff', marginTop: '2%', fontWeight: 'bold' }}>Complete Customization</p>
                                    <p style={{ fontSize: '15px', color: '#fff', marginTop: '2%' }}>Tailor every aspect of your blog to meet client needs.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-start" /* style={{ justifyContent: 'flex-end', display: 'flex' }} */>
                                <img src={key_features} style={{
                                    width: isMobile ? '100%' : '338px',
                                    maxWidth: '100%'
                                }}></img>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* Key Features Section End */}

                    {/* How it works section */}
                    <div
                        ref={(el) => sections.current[2] = el}
                        className="section howit-works-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-1 col-lg-1"></div>
                            <div className="col-sm-12 col-md-5 col-lg-5">
                                <img src={howitworks1} style={{
                                    width: isMobile ? '100%' : '338px',
                                    maxWidth: '100%'
                                }}></img>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-start" /* style={{ justifyContent: 'flex-end', display: 'flex' }} */>
                                <img src={howitworks2} style={{
                                    width: isMobile ? '100%' : '500px',
                                    maxWidth: '100%'
                                }}></img>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* How it works section End */}

                    {/* Benefit Section */}
                    <div
                        ref={(el) => sections.current[3] = el}
                        className="section benefit-features-section"
                        style={{
                            backgroundImage: `url(${benefits_new})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>Who Can Benefit?</h4>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    {/* !isMobile && isIpadMini ?  */<div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div> /* : '' */}
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: isMobile ? '100%' : '105px' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Independent Podcasters</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    Monetize content and expand reach.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: isMobile ? '100%' : '105px' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Podcast Agencies</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    Provide added value to clients.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* !isMobile && isIpadMini ? */ <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div> /* : '' */}
                                </div>

                                <div className="row" style={{ marginTop: isMobile ? '' : '1%' }}>
                                    {/* !isMobile && isIpadMini ? */ <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div>/*  : '' */}
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '100%' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Freelance Marketers</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    Efficient tools for social media and SEO.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '100%' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Business Podcasters</h5>
                                                <p className="card-text" style={{ fontSize: '15px' }}>
                                                    Generate income from every episode.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* Benefit Section End */}

                    {/* Why choose podcast monster Section */}
                    <div
                        ref={(el) => sections.current[4] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            // backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            // backgroundColor: '#eeb0b1', // Overlay color with reduced opacity
                            backgroundColor: '#B9474A',
                            // backgroundBlendMode: 'overlay', // Blends the image with the overlay color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <h4 style={{ color: '#000', fontSize: '33px', fontWeight: 'bold' }}>Why Choose Podcast Monster?</h4>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#000'/* , fontWeight: 'bold' */ }}>New Revenue Streams</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#000' }}>
                                                    Generate additional income from every episode through content creation and ad placement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#000'/* , fontWeight: 'bold' */ }}>Boost SEO and Discoverability</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#000' }}>
                                                    Enhance online presence using targeted keywords and SEO strategies.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}>
                                        <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}>
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ fontSize: '18px', color: '#000'/* , fontWeight: 'bold' */ }}>Expand Your Audience</h5>
                                                <p className="card-text" style={{ fontSize: '16px', color: '#000' }}>
                                                    Distribute high-quality content across multiple platforms to attract new listeners.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* Why choose podcast monster Section End */}

                    {/* customization options section */}
                    <div
                        ref={(el) => sections.current[5] = el}
                        className="section custo-options-section"
                        style={{
                            backgroundImage: `url(${customizationOption})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>Customization Options</h4>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                    <div className={isMobile ? "col-sm-12 col-md-1 col-lg-1" : "col-sm-12 col-md-1 col-lg-1 d-flex justify-content-end"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                        <p className={/* isMobile ? "" :  */"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>1</p>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '2%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '105px' }}> */}
                                        {/* <div className="card-body"> */}
                                        <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Content Editing</h5>
                                        <p className="card-text" style={{ fontSize: '15px', marginTop: '5%' }}>
                                            Edit and optimize each blog post to suit your audience's needs.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>

                                    <div className={isMobile ? "col-sm-12 col-md-1" : "col-sm-12 col-md-1 col-lg-1 d-flex justify-content-end"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                        <p className={/* isMobile ? "" : */ "responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>2</p>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '2%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '105px' }}> */}
                                        {/* <div className="card-body"> */}
                                        <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Visual Enhancements</h5>
                                        <p className="card-text" style={{ fontSize: '15px', marginTop: '5%' }}>
                                            Add high-quality images and embedded videos to engage viewers.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    {isMobile ? '' : <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}></div>}
                                </div>

                                <div className="row" style={{ marginTop: isMobile ? '' : '4%' }}>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                    <div className={isMobile ? "col-sm-12 col-md-1 col-lg-1" : "col-sm-12 col-md-1 col-lg-1 d-flex justify-content-end"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                        <p className={/* isMobile ? "" :  */"responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>3</p>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '2%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '100%' }}> */}
                                        {/* <div className="card-body"> */}
                                        <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>SEO Optimization</h5>
                                        <p className="card-text" style={{ fontSize: '15px', marginTop: '5%' }}>
                                            Include keywords and meta descriptions for better search engine rankings.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className={isMobile ? "col-sm-12 col-md-1 col-lg-1" : "col-sm-12 col-md-1 col-lg-1 d-flex justify-content-end"} style={{ marginTop: isMobile ? '4%' : '' }}>
                                        <p className={/* isMobile ? "" : */ "responsive-p-custopti"} style={{ background: '#790709', width: isMobile ? '10%' : '35%', borderRadius: '4px', padding: isMobile ? '1%' : '2%', textAlign: 'center', height: '30px' }}>4</p>
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '2%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#790709', height: '100%' }}> */}
                                        {/* <div className="card-body"> */}
                                        <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold' }}>Monetization Features</h5>
                                        <p className="card-text" style={{ fontSize: '15px', marginTop: '5%' }}>
                                            Insert affiliate links or ads to generate additional income.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* customization options section end */}

                    {/* Getting Started Section */}
                    <div
                        ref={(el) => sections.current[6] = el}
                        className="section getting-started-section"
                        style={{
                            // backgroundImage: `url(${customization_options})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            backgroundColor: '#B9474A',
                            // backgroundBlendMode: 'overlay',
                            // height: '100vh',
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className="row" style={{ /* marginTop: '3%', */ padding: '2%' }}>
                            <div className="col-sm-12 col-md-2 col-lg-2"></div>

                            <div className="col-sm-12 col-md-8 col-lg-8" style={{ borderRadius: '10px', backgroundColor: 'rgba(0, 0, 0, 0.5)', backgroundBlendMode: 'overlay', padding: '5%' }}>
                                <h4 style={{ color: '#fff', fontSize: '33px' }}>Getting Started Today</h4>
                                <p style={{ marginTop: '4%', cursor: 'pointer' }} onClick={() => setArrowClick1(!arrowClick1)}>{arrowClick1 ? <RightArrow /> : <ArrowIcon />}<span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>How to sign up</span></p>
                                {!arrowClick1 ? <p style={{ display: 'inline-flex', alignItems: 'flex-start', margin: 0 }}><LineIcon style={{ marginLeft: '7px' }} /><span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>Add a youtube url or upload a video or audio file.  Click Create Blog.  Our AI Agents will start creating your blog content instantly.</span></p> : ''}

                                <p style={{ cursor: 'pointer' }} onClick={() => setArrowClick2(!arrowClick2)}>{arrowClick2 ? <RightArrow /> : <ArrowIcon />}<span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>What's included in the free trial?</span></p>
                                {!arrowClick2 ? <p style={{ display: 'inline-flex', alignItems: 'flex-start', margin: 0 }}><LineIcon style={{ marginLeft: '7px' }} /><span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>You'll receive 2 free blogs to start, allowing you to experience Podcast Monster's benefits firsthand.  All features are available with the 2 free blogs.</span></p> : ''}

                                <p style={{ cursor: 'pointer' }} onClick={() => setArrowClick3(!arrowClick3)}>{arrowClick3 ? <RightArrow /> : <ArrowIcon />}<span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>How soon can I start monetizing?</span></p>
                                {!arrowClick3 ? <p style={{ display: 'inline-flex', alignItems: 'flex-start', margin: 0 }}><LineIcon style={{ marginLeft: '7px' }} /><span style={{ color: '#fff', fontSize: '15px', marginLeft: '3%' }}>You can start offering services and monetizing your content immediately after signing up.</span></p> : ''}
                            </div>

                            <div className="col-sm-12 col-md-2 col-lg-2"></div>

                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* Getting Started Section End */}

                    {/* FAQ Section */}
                    <div
                        ref={(el) => sections.current[7] = el}
                        className="section faq-questions-section"
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            <h4 style={{ color: '#fff', fontSize: '33px', fontWeight: 'bold' }}>FREQUENTLY ASKED QUESTIONS</h4>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ textAlign: 'center', marginTop: '5%' }}>
                                <img src={faq_robo} style={{
                                    width: isMobile ? '100%' : 'auto',
                                    maxWidth: '100%'
                                }}></img>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ justifyContent: 'center', flexDirection: 'column', width: isMobile ? '100%' : '', alignItems: 'center', marginTop: isMobile ? '10%' : '' }}>

                                <div className="accordion" id="regularAccordionRobots">
                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeadingFirst" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapseFirst"
                                                aria-expanded="true"
                                                aria-controls="regularCollapseFirst"
                                                style={{ color: '#fff' }}
                                            >
                                                What is Podcast Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapseFirst"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="regularHeadingFirst"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Podcast Monster is a platform that helps podcast hosts monetize and distribute their content. It generates SEO-optimized blogs from each podcast episode, allowing hosts to share content across multiple platforms and create new income streams through content creation services, ads, and affiliate links.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading2" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse2"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse2"
                                                style={{ color: '#fff' }}
                                            >
                                                How does Podcast Monster help me make money?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse2"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeadingFirst"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Podcast Monster enables you to offer content creation and social media marketing as a service to your guests and clients. You can charge clients for customized blogs, add ads or affiliate links, and use the platform as a new revenue stream for your podcast.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading3" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse3"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse3"
                                                style={{ color: '#fff' }}
                                            >
                                                What platforms can I share the blogs on?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse3"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading3"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                The blogs created by Podcast Monster can be shared on social media platforms like Facebook, Twitter, and LinkedIn, as well as blogging platforms like Medium, Ghost, and Blogger. You can also download the blogs as Word documents or PDFs for easy sharing with clients.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading4" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse4"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse4"
                                                style={{ color: '#fff' }}
                                            >
                                                Do I need any technical skills to use Podcast Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse4"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading4"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                No technical skills are required! Podcast Monster automatically generates SEO-optimized blogs from your podcast episodes. You can easily customize and share the content without needing any advanced knowledge.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading5" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse5"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse5"
                                                style={{ color: '#fff' }}
                                            >
                                                How does the free trial work?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse5"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading5"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                When you sign up for Podcast Monster, you’ll receive 2 free blogs to get started. This allows you to test the platform’s features and see how it can help grow your podcast.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading6" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse6"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse6"
                                                style={{ color: '#fff' }}
                                            >
                                                Can I edit the blogs created by Podcast Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse6"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading6"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, you can fully customize each blog. You can edit the content, add keywords, insert images, embed videos, adjust titles, and include call-to-action button, ads, or affiliate links to meet your needs or your clients’ requirements.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading7" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse7"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse7"
                                                style={{ color: '#fff' }}
                                            >
                                                What kind of content does Podcast Monster create?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse7"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading7"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Podcast Monster takes content from your podcast audio, video, or YouTube episodes and turns it into unique, high-quality, SEO-optimized blogs. These blogs are designed to engage audiences and improve your podcast's discoverability across the web.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading8" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse8"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse8"
                                                style={{ color: '#fff' }}
                                            >
                                                Is Podcast Monster suitable for any type of podcast?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse8"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading8"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, Podcast Monster works for any type of podcast, regardless of genre. The platform is designed to help all podcasters—from independent creators to large agencies—generate new income streams and reach a broader audience.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading9" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse9"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse9"
                                                style={{ color: '#fff' }}
                                            >
                                                How much does Podcast Monster cost?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse9"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading9"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Podcast Monster charges a small monthly fee to podcast hosts. This fee gives you access to all features, including blog creation, customization, and distribution across multiple platforms.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading10" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse10"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse10"
                                                style={{ color: '#fff' }}
                                            >
                                                Can I use Podcast Monster as an agency for my clients?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse10"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading10"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Absolutely! Podcast Monster allows you to offer content creation services to your clients, enabling you to set your own rates and keep the profit. This is a great way to add value to your services and grow your podcast business.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading11" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse11"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse11"
                                                style={{ color: '#fff' }}
                                            >
                                                Can I cancel my subscription at any time?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse11"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading11"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, you can cancel your subscription at any time. However, we’re confident that once you see how easy it is to monetize and distribute your content with Podcast Monster, you’ll want to keep using the platform to grow your podcast business!
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading12" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse12"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse12"
                                                style={{ color: '#fff' }}
                                            >
                                                Can I add my own ads or affiliate links to the blogs?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse12"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading12"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes! Podcast Monster allows you to add ads or affiliate links to each blog, enabling you to generate additional revenue from the content created.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading13" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse13"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse13"
                                                style={{ color: '#fff' }}
                                            >
                                                Will Podcast Monster work with my podcast's language?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse13"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading13"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Yes, Podcast Monster creates blogs in the same language as your podcast, ensuring that the content is authentic and resonates with your audience.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-2 mb-2">
                                        <h2 id="regularHeading14" className="accordion-header">
                                            <button
                                                className="accordion-button accordion_style px-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#regularCollapse14"
                                                aria-expanded="false"
                                                aria-controls="regularCollapse14"
                                                style={{ color: '#fff' }}
                                            >
                                                How do I get started with Podcast Monster?
                                            </button>
                                        </h2>
                                        <div
                                            id="regularCollapse14"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="regularHeading14"
                                            data-bs-parent="#regularAccordionRobots"
                                        >
                                            <div className="accordion-body">
                                                Getting started is easy! Sign up today and receive 2 free blogs to test out the platform. After your trial, you can choose a plan that best fits your needs and start transforming each podcast episode into an opportunity for growth and income.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* FAQ Section End */}

                    {/* Footer section */}
                    {/* <div
                        style={{
                            backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // To add a darker overlay
                            backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <p style={{ color: '#fff', fontSize: '15px' }}>Copyright <CopyRightIcon /> 2024 <a href="/" style={{ color: '#F65F62', textDecoration: 'underline', fontWeight: 'bold' }}>Code-Genie.ai</a> | All Rights Reserved.</p>
                            </div>
                        </div>
                    </div> */}
                    {/* footer section end*/}
                </div >


            </div>
        </>
    )
}

export default TrafficMonsLandingNew;